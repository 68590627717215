import { useEffect, useState } from 'react';
import { DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { Title, Button, Label, Dialog } from 'components/form-components';
import { ColorPicker } from 'material-ui-color';

const ShapeColorDialog = ({
  open,
  data = { strokeColor: '#000', fillColor: '#00000000' },
  onClose,
  onSubmit,
}) => {
  const [strokeColor, setStrokeColor] = useState(data.strokeColor);
  const [fillColor, setFillColor] = useState(data.fillColor);

  useEffect(() => {
    setStrokeColor(data.strokeColor);
    setFillColor(data.fillColor);
  }, [data]);

  useEffect(() => {
    setTimeout(() => {
      document
        .querySelectorAll(`.ColorPicker-MuiInput-input`)
        .forEach((element) => {
          const btn = element.parentNode.parentNode.parentNode.querySelector(
            '.muicc-colorpicker-button>span.MuiButton-label>div'
          );
          if (btn) {
            btn.innerHTML =
              element.value === 'none'
                ? `<svg fill="red" viewBox="0 0 24 24" aria-hidden="true">
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
            </svg>`
                : '';
          }
        });
    });
  }, [strokeColor, fillColor, open]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ strokeColor, fillColor });
  };

  const handleStrokeColorChange = (value) => {
    setStrokeColor(value?.css?.backgroundColor || value);
  };

  const handleFillColorChange = (value) => {
    setFillColor(value?.css?.backgroundColor || value);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Title>Color Palette</Title>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Label>Stroke Color:</Label>
          <ColorPicker value={strokeColor} onChange={handleStrokeColorChange} />
          <Label>Fill Color:</Label>
          <ColorPicker value={fillColor} onChange={handleFillColorChange} />
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="primary" variant="contained">
            OK
          </Button>
          <Button
            type="reset"
            color="secondary"
            variant="contained"
            onClick={onClose}
          >
            CANCEL
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ShapeColorDialog;
