import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WidgetEditor } from 'components';
import { getFigures, setSelectedParticipant } from 'actions';
import SocketContext from 'contexts/socketContext';
import useWebSocket from 'hooks/useWebSocket';

export default function CanvasBoard() {
  const dispatch = useDispatch();
  const socket = useWebSocket();
  const { profile } = useSelector((state) => state.auth);
  const { index, figures, copiedFigure, selectedParticipant } = useSelector(
    (state) => state.board
  );

  const filteredFigures = useMemo(
    () =>
      figures.filter(
        (f) => f.canvas === index && selectedParticipant === f.creatorUUID
      ),
    [index, figures, selectedParticipant]
  );

  useEffect(() => {
    if (selectedParticipant) {
      dispatch(getFigures(selectedParticipant));
    }
    // eslint-disable-next-line
  }, [selectedParticipant]);

  useEffect(() => {
    if (!selectedParticipant) {
      dispatch(setSelectedParticipant(profile.uuid));
    }
    // eslint-disable-next-line
  }, [profile, selectedParticipant]);

  return (
    <SocketContext.Provider value={{ socket }}>
      <WidgetEditor
        figures={filteredFigures}
        copiedFigure={copiedFigure}
        index={index}
        editable={!!selectedParticipant}
      />
    </SocketContext.Provider>
  );
}
