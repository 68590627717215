import * as React from 'react';
import { styled } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Box, Grid } from '@material-ui/core';
import RedoIcon from '@material-ui/icons/Redo';
import UndoIcon from '@material-ui/icons/Undo';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 28,
    paddingLeft: 32,
    paddingRight: 32,
    paddingBottom: 32,
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const Title = styled(Typography)({
  fontWeight: 'bold',
  fontSize: 16,
  marginBottom: 53,
  textAlign: 'center',
});

const DescHeader = styled(Typography)({
  fontWeight: 'bold',
  fontSize: 14,
  letterSpacing: 0.49,
  marginBottom: 9,
});

const DescContent = styled(Typography)({
  fontSize: 12,
  letterSpacing: 0.42,
});

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: 'absolute',
            right: 16,
            top: 32,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function HelpDialog({ open, onClose }) {
  return (
    <BootstrapDialog onClose={onClose} open={open} scroll="body">
      <BootstrapDialogTitle onClose={onClose}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <img
            src="assets/img/logo.svg"
            alt="logo"
            style={{ height: 84, padding: 12 }}
          />
        </Box>
      </BootstrapDialogTitle>
      <DialogContent>
        <Title>Canvas Controls</Title>
        <Grid container spacing={4}>
          <Grid
            item
            xs={1}
            container
            alignItems="center"
            justifyContent="center"
          >
            <img
              src="assets/img/help/mouse-double-click-icon.svg"
              alt="Double Click"
            />
          </Grid>
          <Grid item xs={11}>
            <DescHeader>Pan & Move the Canvas</DescHeader>
            <DescContent>
              Double-click the left mouse button, hold the second click and move
              the mouse to pan the canvas. Release the button to exit panning.
            </DescContent>
          </Grid>
          <Grid
            item
            xs={1}
            container
            alignItems="center"
            justifyContent="center"
          >
            <img
              src="assets/img/help/scroll-wheel-icon.svg"
              alt="Scroll Wheel"
            />
          </Grid>
          <Grid item xs={11}>
            <DescHeader>Zoom in and out of the Canvas</DescHeader>
            <DescContent>
              Scroll the mouse wheel up or down to zoom in and out of the
              canvas. A mouse with a scroll wheel or scrollable third mouse
              button is required to perform this action.
            </DescContent>
          </Grid>
          <Grid
            item
            xs={1}
            container
            alignItems="center"
            justifyContent="center"
          >
            <img
              src="assets/img/help/right-click-icon.svg"
              alt="Right Click"
              style={{ marginLeft: 10 }}
            />
          </Grid>
          <Grid item xs={11}>
            <DescHeader>Show an object’s context menu</DescHeader>
            <DescContent>
              After left clicking to select an object on the canvas, right click
              the mouse once to open up the context menu. This opens up a menu
              with object properties you can adjust.
            </DescContent>
          </Grid>
          <Grid
            item
            xs={1}
            container
            alignItems="center"
            justifyContent="center"
          >
            <img src="assets/img/help/left-click-icon.svg" alt="Left Click" />
          </Grid>
          <Grid item xs={11}>
            <DescHeader>Select multiple objects</DescHeader>
            <DescContent>
              Click the left mouse button once and hold. Then drag the blue
              square over the objects you want to select and release the mouse
              button to complete the selection.
            </DescContent>
          </Grid>
          <Grid
            item
            xs={1}
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <UndoIcon color="primary" />
            <RedoIcon color="primary" />
          </Grid>
          <Grid item xs={11}>
            <DescHeader>Select multiple objects</DescHeader>
            <DescContent>
              Click the left mouse button once and hold. Then drag the blue
              square over the objects you want to select and release the mouse
              button to complete the selection.
            </DescContent>
          </Grid>
        </Grid>
      </DialogContent>
    </BootstrapDialog>
  );
}
