import ActionTypes from 'utils/constants/action-types';
import { createAction } from 'redux-actions';
import { setUsers } from './auth';
import { setLoading } from './auxiliary';
import * as API from 'services/boards';
import { toArray } from 'utils/helpers';

export const addFigure = createAction(
  ActionTypes.ADD_FIGURE,
  (payload) => payload
);
export const removeFigure = createAction(
  ActionTypes.REMOVE_FIGURE,
  (payload) => payload
);
export const setFigure = createAction(
  ActionTypes.SET_FIGURE,
  (payload) => payload
);
export const setCopiedFigure = createAction(
  ActionTypes.SET_COPIED_FIGURE,
  (payload) => payload
);
export const setBoard = createAction(
  ActionTypes.SET_BOARD,
  (payload) => payload
);
export const setCanvasIndex = createAction(
  ActionTypes.SET_CANVAS_INDEX,
  (payload) => payload
);
export const setFigureHovered = createAction(
  ActionTypes.SET_FIGURE_HOVERED,
  (payload) => payload
);
export const setSelectedParticipant = createAction(
  ActionTypes.SET_SELECTED_PARTICIPANT,
  (payload) => payload
);
export const setSelectedFigure = createAction(
  ActionTypes.SET_SELECTED_FIGURE,
  (payload) => payload
);
export const addHistory = createAction(
  ActionTypes.ADD_HISTORY,
  (redo, undo) => ({ id: new Date().getTime(), redo, undo })
);

const createHistory = createAction(
  ActionTypes.CREATE_HISTORY,
  (payload) => payload
);
const updateHistory = createAction(
  ActionTypes.UPDATE_HISTORY,
  (payload) => payload
);
const deleteHistory = createAction(
  ActionTypes.DELETE_HISTORY,
  (payload) => payload
);
const updateHistoryCursor = createAction(
  ActionTypes.UPDATE_HISTORY_CURSOR,
  (payload) => payload
);
const updateHistoryAction = createAction(
  ActionTypes.UPDATE_HISTORY_ACTION,
  (id, action) => ({ id, action })
);

export const getBoard = () => (dispatch, getState) => {
  dispatch(setLoading(true));

  const { boardUUID } = getState().auth.profile;
  API.getBoard(boardUUID).then((data) => {
    dispatch(setBoard(data));
    dispatch(setLoading(false));
  });
};

export const getFigures = (participantUUID) => (dispatch, getState) => {
  dispatch(setLoading(true));

  const { boardUUID, uuid } = getState().auth.profile;

  API.getFigures(boardUUID, participantUUID || uuid).then((data) => {
    dispatch(setBoard(data));
    dispatch(setLoading(false));
  });
};

export const getParticipants = () => (dispatch, getState) => {
  const { users } = getState().auth;
  if (users.length) {
    return;
  }

  dispatch(setLoading(true));

  const { boardUUID } = getState().auth.profile;
  API.getParticipants(boardUUID).then((data) => {
    dispatch(setUsers(data.participants));
    dispatch(setLoading(false));
  });
};

export const switchCanvas = (index) => (dispatch, getState) => {
  dispatch(setCanvasIndex(index));
  const state = getState();
  const { profile } = state.auth;
  API.switchCanvas(profile.boardUUID, index);
};

export const updateBoard = (boardUUID, params) => (dispatch) => {
  API.updateBoard(boardUUID, params).then((data) => {
    dispatch(setBoard(data));
  });
};

export const createFigure =
  (figures, history = false, actionId = null) =>
  (dispatch, getState) => {
    dispatch(setLoading(true));
    const {
      index: canvas,
      uuid: boardUUID,
      history: { actions },
      selectedParticipant: creatorUUID,
    } = getState().board;
    Promise.all(
      toArray(figures).map((figure) =>
        API.createFigure(creatorUUID, { ...figure, canvas, boardUUID })
      )
    ).then((createdFigures) => {
      dispatch(addFigure(createdFigures));
      if (!history) {
        dispatch(
          addHistory(
            createHistory(figures),
            deleteHistory(toArray(createdFigures).map((f) => f.uuid))
          )
        );
      } else {
        const action = actions.find((h) => h.id === actionId);
        if (action.undo.type === ActionTypes.DELETE_HISTORY) {
          dispatch(
            updateHistoryAction(actionId, {
              ...action,
              undo: {
                ...action.undo,
                payload: createdFigures.map((f) => f.uuid),
              },
            })
          );
        } else if (action.redo.type === ActionTypes.DELETE_HISTORY) {
          dispatch(
            updateHistoryAction(actionId, {
              ...action,
              redo: {
                ...action.redo,
                payload: createdFigures.map((f) => f.uuid),
              },
            })
          );
        }
      }
      dispatch(setLoading(false));
    });
  };

export const updateFigure =
  (figure, history = false, actionId = null) =>
  (dispatch, getState) => {
    const { index: canvas, uuid: boardUUID, figures } = getState().board;
    dispatch(setFigure(figure));
    if (!history) {
      dispatch(
        addHistory(
          updateHistory(figure),
          updateHistory(figures.find((f) => f.uuid === figure.uuid))
        )
      );
    }
    API.updateFigure(figure.uuid, { ...figure, canvas, boardUUID });
  };

export const deleteFigure =
  (uuids, history = false, actionId = null) =>
  (dispatch, getState) => {
    const { figures } = getState().board;

    dispatch(setLoading(true));

    Promise.all(toArray(uuids).map((uuid) => API.deleteFigure(uuid))).then(
      () => {
        dispatch(removeFigure(uuids));
        if (!history) {
          dispatch(
            addHistory(
              deleteHistory(uuids),
              createHistory(figures.filter((f) => uuids.includes(f.uuid)))
            )
          );
        }
        dispatch(setLoading(false));
      }
    );
  };

export const copyCanvasTo = (tarIdx) => (dispatch, getState) => {
  dispatch(setLoading(true));

  let { figures, selectedParticipant, index: srcIdx } = getState().board;
  figures = figures.filter((f) => f.creatorUUID === selectedParticipant);
  const tarFigures = figures.filter((f) => f.canvas === tarIdx);
  const srcFigures = figures.filter((f) => f.canvas === srcIdx);

  Promise.all(tarFigures.map((f) => API.deleteFigure(f.uuid)))
    .then(() =>
      Promise.all(
        srcFigures.map((f) =>
          API.createFigure(selectedParticipant, { ...f, canvas: tarIdx })
        )
      )
    )
    .then((createdFigures) => {
      dispatch(removeFigure(tarFigures.map((f) => f.uuid)));
      dispatch(addFigure(createdFigures));
      dispatch(setLoading(false));
    });
};

const HISTORY_ACTION_MAP = {
  [ActionTypes.CREATE_HISTORY]: createFigure,
  [ActionTypes.UPDATE_HISTORY]: updateFigure,
  [ActionTypes.DELETE_HISTORY]: deleteFigure,
};

export const undoHistory = () => (dispatch, getState) => {
  const {
    history: { actions, cursor },
  } = getState().board;

  if (cursor > actions.length - 1) {
    return;
  }

  const {
    undo: { type, payload },
    id,
  } = actions[cursor];
  dispatch(HISTORY_ACTION_MAP[type](payload, true, id));
  dispatch(updateHistoryCursor(cursor + 1));
};

export const redoHistory = () => (dispatch, getState) => {
  const {
    history: { actions, cursor },
  } = getState().board;

  if (cursor === 0) {
    return;
  }

  const {
    redo: { type, payload },
    id,
  } = actions[cursor - 1];
  dispatch(HISTORY_ACTION_MAP[type](payload, true, id));
  dispatch(updateHistoryCursor(cursor - 1));
};
