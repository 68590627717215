import { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setupSocket } from 'services/websocket';
import { removeFigure, setBoard, setCanvasIndex, setFigure } from 'actions';

const useWebSocket = () => {
  const dispatch = useDispatch();
  const { accessToken, profile } = useSelector((state) => state.auth);
  const { selectedParticipant } = useSelector((state) => state.board);
  const socket = useMemo(
    () => accessToken && setupSocket(accessToken),
    [accessToken]
  );

  useEffect(() => {
    if (socket) {
      socket.on('figuresCU', (figure) => {
        if (figure.emitterUUID !== profile.uuid) {
          dispatch(setFigure(figure));
        }
      });
      socket.on('figuresD', (figure) => {
        if (figure.emitterUUID !== profile.uuid) {
          dispatch(removeFigure(figure.uuid));
        }
      });
      socket.on('board', (board) => dispatch(setBoard(board)));
      socket.on('canvas', (user) => {
        dispatch(setCanvasIndex(user.currentCanvas));
      });
    }
    return () => socket.removeAllListeners();
  }, [socket, profile, selectedParticipant, dispatch]);

  return socket;
};

export default useWebSocket;
