import _ from 'lodash';
import { toArray } from 'utils/helpers';
import { MAX_HISTORY_NUMBER } from 'utils/constants/values';
import ActionTypes from 'utils/constants/action-types';

export const INITIAL_BOARD_STATE = Object.freeze({
  index: 0,
  participants: [],
  selectedParticipant: null,
  name: '',
  figures: [],
  uuid: null,
  copiedFigure: [],
  history: {
    cursor: 0,
    actions: [],
  },
});

export const setBoardUpdater = (state, { payload }) => ({
  ...state,
  ...payload,
});

export const setCanvasIndexUpdater = (state, { payload: index }) => ({
  ...state,
  index,
});

export const setCopiedFigureUpdater = (state, { payload: uuids }) => ({
  ...state,
  copiedFigure: toArray(uuids).map((uuid) =>
    state.figures.find((f) => f.uuid === uuid)
  ),
});

export const addFigureUpdater = (state, { payload }) => ({
  ...state,
  figures: [...state.figures, ...toArray(payload)],
});

export const removeFigureUpdater = (state, { payload: uuid }) => ({
  ...state,
  figures: state.figures.filter((f) =>
    Array.isArray(uuid) ? !uuid.includes(f.uuid) : f.uuid !== uuid
  ),
});

export const setFigureUpdater = (state, { payload: figure }) => ({
  ...state,
  figures: state.figures.find((f) => f.uuid === figure.uuid)
    ? state.figures.map((f) =>
        f.uuid === figure.uuid ? _.merge({}, f, figure) : f
      )
    : state.figures.concat(figure),
});

export const setFigureHoveredUpdater = (state, { payload: figureId }) => ({
  ...state,
  figures: state.figures.map((f) => ({ ...f, hovered: f.uuid === figureId })),
});

export const setSelectedParticipantUpdater = (
  state,
  { payload: selectedParticipant }
) => ({
  ...state,
  selectedParticipant,
});

export const setSelectedFigureUpdater = (state, { payload: uuid }) => ({
  ...state,
  figures: state.figures.map((f) => ({ ...f, selected: f.uuid === uuid })),
});

export const addHistoryUpdater = (state, { payload }) => {
  const actions = state.history.actions.filter((a) => {
    if (payload.redo.type === ActionTypes.DELETE_HISTORY) {
      if (
        a.redo.type === ActionTypes.UPDATE_HISTORY &&
        toArray(payload.redo.payload).includes(a.redo.payload.uuid)
      ) {
        return false;
      } else if (
        a.undo.type === ActionTypes.DELETE_HISTORY &&
        _.intersection(toArray(payload.redo.payload), toArray(a.undo.payload))
          .length > 0
      ) {
        return false;
      }
    }
    return true;
  });

  return {
    ...state,
    history: {
      ...state.history,
      cursor: 0,
      actions: [payload, ...actions].slice(0, MAX_HISTORY_NUMBER),
    },
  };
};

export const updateHistoryCursorUpdater = (state, { payload: cursor }) => ({
  ...state,
  history: {
    ...state.history,
    cursor: cursor,
  },
});

export const updateHistoryActionUpdater = (
  state,
  { payload: { id, action } }
) => ({
  ...state,
  history: {
    ...state.history,
    actions: state.history.actions.map((a) => (a.id === id ? action : a)),
  },
});
